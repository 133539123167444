import React from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
} from "@mui/material";

import { useMutation, useLazyQuery, useReactiveVar } from "@apollo/client";

import { permissionsVar } from "../../../../../graphql/localVariables/user";
import { CREATE_EDIT_PROJECT } from "../../../../../graphql/mutations/admin/planning-project";
import {
  GET_PROJECTS,
  GET_PROJECT_DATA,
  GET_SITES,
} from "../../../../../graphql/queries/admin/planning-project";
import { GET_SEARCH_TEMPLATES } from "../../../../../graphql/queries/ha";
import sortByKeys from "../../../../../utils/sortByKeys";
import { states } from "../../../../../utils/states";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../../CustomComponents/OnErrorFunction";
import { CustomSwitch } from "../../../../CustomComponents/Switch";
import { CustomTextField } from "../../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_OSP } from "../../../Company/companyAdminQueries";
import SelectedUnselected from "../../Template/SelectedUnselected";
import PreviewImages from "../PreviewImages";
import UploadDialog from "../Upload";

export default function EditProject({ row, open, handleClose, company }) {
  const permissions = useReactiveVar(permissionsVar);
  const [getProjectData, { data, loading: loadingProject }] = useLazyQuery(
    GET_PROJECT_DATA,
    { fetchPolicy: "no-cache" }
  );
  const [getSites, { data: sites }] = useLazyQuery(GET_SITES);
  const [getTemplates, { data: templates }] =
    useLazyQuery(GET_SEARCH_TEMPLATES);

  React.useEffect(() => {
    if (open) {
      getProjectData({
        variables: { projectId: Number(row.id) },
      });
      getSites({
        fetchPolicy: "network-only",
      });
      getTemplates({
        fetchPolicy: "network-only",
      });
    }
  }, [open]);

  const [editProject, { loading: loadingEdit }] = useMutation(
    CREATE_EDIT_PROJECT,
    {
      onCompleted() {
        handleClose();
        onCompletedFunc("Project has been updated");
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        { query: GET_PROJECTS },
        company && {
          query: COMPANY_ADMIN_OSP,
          variables: { id: Number(company.id) },
        },
      ],
    }
  );

  const initialState = {
    isActive: false,
    locations: [],
    name: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    dollar: 0.0,
  };
  const [projectDetails, setProjectDetails] = React.useState(initialState);
  const [editDisabled, setEditDisabled] = React.useState(false);
  const [photos, setPhotos] = React.useState([]);
  const [removedPhotos, setRemovedPhotos] = React.useState([]);

  const [selectedTemplates, setSelectedTemplates] = React.useState([]);
  const [unselectedTemplates, setUnselectedTemplates] = React.useState([]);

  const setInitialState = () => {
    setProjectDetails({
      isActive: data.project.isActive,
      locations: data.project.ownerSiteProjects,
      name: data.project.name,
      addr1: data.project.addrLine1,
      addr2: data.project.addrLine2,
      city: data.project.addrCity,
      state: data.project.addrState,
      zip: data.project.addrZip,
      dollar: data.project.dollarAmount,
    });
    setPhotos(data.project.projectMediaFiles);
    setRemovedPhotos([]);
    let ospTemplates = data.project.ownerSiteProjects[0]
      ? data.project.ownerSiteProjects[0].ownerSiteProjectTemplate
          .filter((t) => t.isActive)
          .map((ospt) => ospt.template)
      : [];

    setSelectedTemplates(ospTemplates);
    setUnselectedTemplates(
      templates.templates.filter(
        (template) => ospTemplates.findIndex((t) => t.id === template.id) < 0
      )
    );
  };

  React.useEffect(() => {
    if (data && templates) {
      setInitialState();
    }
  }, [data, templates]);

  React.useEffect(() => {
    if (!permissions.includes("EDIT_PROJECT")) {
      setEditDisabled(true);
    }
  }, []);

  const onEdit = () => {
    if (projectDetails.name === "") {
      onErrorFunc("Include a name for this project.");
    } else if (!projectDetails.state || projectDetails.state === "") {
      onErrorFunc("Include the state for this project's location.");
    } else if (projectDetails.locations.length === 0) {
      onErrorFunc("Include at least one site associated to this project.");
    } else {
      editProject({
        variables: {
          isActive: projectDetails.isActive,
          projectName: projectDetails.name,
          addrCity: projectDetails.city,
          addrLine1: projectDetails.addr1,
          addrLine2: projectDetails.addr2,
          addrState: projectDetails.state,
          addrZip: projectDetails.zip,
          projectId: row.id,
          OSPinfo: projectDetails.locations.map((l) => {
            return {
              company: l.owner.id,
              site: l.site.id,
              associatedTemplate: selectedTemplates.map((t) => Number(t.id)),
            };
          }),
          dollarAmount: Number(projectDetails.dollar),
          edit: true,
          media: photos.filter((p) => !p.mediaFile),
          removeMedia: removedPhotos,
          changeStatus: false,
        },
      });
    }
  };

  const onCancel = () => {
    handleClose();
    setInitialState();
  };

  const handleProjectDetailsChange = (event) => {
    const { name, value } = event.target;
    setProjectDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
          fontSize: "2rem",
        }}
      >
        PROJECT: <span style={{ color: "#FFB700" }}> {row.name}</span>
      </DialogTitle>
      <DialogContent>
        {loadingProject ? (
          <Grid container justifyContent="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item xs={12} container alignItems="center">
                <FormControlLabel
                  style={{ color: "white" }}
                  control={
                    <CustomSwitch
                      checked={projectDetails.isActive}
                      onChange={(event) =>
                        setProjectDetails((prev) => ({
                          ...prev,
                          isActive: event.target.checked,
                        }))
                      }
                      light
                      disabled={editDisabled}
                    />
                  }
                  label={projectDetails.isActive ? "ACTIVE" : "INACTIVE"}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="select-site-owner"
                  limitTags={3}
                  options={
                    sites
                      ? sortByKeys(
                          sites.ownerSiteProjects.filter((osp) =>
                            company
                              ? osp.owner &&
                                osp.owner.id === company.id &&
                                osp.site
                              : osp.owner && osp.site
                          ),
                          "site.name"
                        )
                      : []
                  }
                  value={projectDetails.locations}
                  onChange={(event, value) =>
                    setProjectDetails((prev) => ({ ...prev, locations: value }))
                  }
                  getOptionLabel={(option) =>
                    `${option.site.name} [${option.owner.name}]`
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.site.id === value.site.id &&
                    option.owner.id === value.owner.id
                  }
                  disableClearable
                  disabled={editDisabled}
                  multiple
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {`${option.site.name} [${option.owner.name}]`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      style={{ color: "#fff" }}
                      variant="standard"
                      label={"Location:"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Project Name:"
                  variant="standard"
                  value={projectDetails.name}
                  onChange={(event) => handleProjectDetailsChange(event)}
                  name="name"
                  disabled={editDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Address Line 1:"
                  variant="standard"
                  value={projectDetails.addr1}
                  onChange={(event) => handleProjectDetailsChange(event)}
                  name="addr1"
                  disabled={editDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Address Line 2:"
                  variant="standard"
                  value={projectDetails.addr2}
                  onChange={(event) => handleProjectDetailsChange(event)}
                  name="addr2"
                  disabled={editDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="City:"
                  variant="standard"
                  value={projectDetails.city}
                  onChange={(event) => handleProjectDetailsChange(event)}
                  name="city"
                  disabled={editDisabled}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="select-site-state"
                  options={states}
                  value={projectDetails.state}
                  onChange={(event, value) =>
                    setProjectDetails((prev) => ({ ...prev, state: value }))
                  }
                  name="state"
                  disabled={editDisabled}
                  disableClearable
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      style={{ color: "#fff" }}
                      variant="standard"
                      label={"State:"}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Zipcode:"
                  variant="standard"
                  value={projectDetails.zip}
                  onChange={(event) => handleProjectDetailsChange(event)}
                  name="zip"
                  disabled={editDisabled}
                  type="number"
                  inputProps={{ maxLength: 5 }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextField
                  label="Dollar Amount:"
                  variant="standard"
                  value={projectDetails.dollar}
                  onChange={(event) => handleProjectDetailsChange(event)}
                  name="dollar"
                  disabled={editDisabled}
                  type="number"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <SelectedUnselected
                  selected={selectedTemplates}
                  unselected={unselectedTemplates}
                  setSelected={setSelectedTemplates}
                  setUnselected={setUnselectedTemplates}
                  getName={(item) => item.name}
                  label="Templates"
                  selectedLabel
                  showSearch
                />
              </Grid>
            </Grid>

            <UploadDialog
              files={photos}
              handleFilesChange={(files) => setPhotos(files)}
              disabled={editDisabled}
            />
            <PreviewImages
              fileObjects={photos}
              handleFilesChange={(files) => setPhotos(files)}
              setRemovedPhotos={setRemovedPhotos}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onEdit}
          loading={loadingEdit}
          disabled={editDisabled}
        >
          SAVE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
