import React from "react";

import { useQuery, useReactiveVar } from "@apollo/client";

import { initialState } from "../../../graphql/localVariables/observation";
import { onlineVar } from "../../../graphql/localVariables/user";
import { observationMutations } from "../../../graphql/mutations";
import { GET_CURRENT_USER } from "../../../graphql/queries/auth";
import Layout from "../../Layout";
import ObservationForm from "../Form";
import { useSubmitObservation } from "./useSubmitObservation";

export default function CreateObservation() {
  const online = useReactiveVar(onlineVar);

  // Queries
  const { data: userData } = useQuery(GET_CURRENT_USER);

  const { updateAllCreateObservation } = observationMutations;

  // Local Variables
  const [expanded, setExpanded] = React.useState(1);
  const [currentSection, setCurrentSection] = React.useState(1);

  // Clear Form
  const resetForm = () => {
    setCurrentSection(1);
    setExpanded(1);
    updateAllCreateObservation({
      ...initialState,
      date: new Date(),
      time: new Date(),
    });
  };

  const { executeSubmit } = useSubmitObservation(userData, online, resetForm);

  return (
    <Layout>
      <ObservationForm
        type={"CREATE"}
        executeSubmit={executeSubmit}
        resetForm={resetForm}
        expanded={expanded}
        setExpanded={setExpanded}
        setCurrentSection={setCurrentSection}
        currentSection={currentSection}
        riskThreshold={userData?.currentUser.company?.riskThreshold}
      />
    </Layout>
  );
}
