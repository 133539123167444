import { useNavigate } from "react-router-dom";

import { useMutation } from "@apollo/client";
import moment from "moment";

import { createObservationVar } from "../../../graphql/localVariables/observation";
import {
  authMutations,
  observationMutations,
} from "../../../graphql/mutations";
import { onErrorFunc } from "../../CustomComponents/OnErrorFunction";

// CREATE
export const useSubmitObservation = (userData, online, resetForm) => {
  const navigate = useNavigate();

  const { updateSnackbar } = authMutations;
  const { OBSERVATION_CREATE, updateKeyCreateObservation } =
    observationMutations;

  const handleError = (error) => {
    updateKeyCreateObservation("disabled", false);
    onErrorFunc(error);
  };

  const handleSuccess = (success) => {
    if (!success.observationCreate.observation) {
      handleError("There was an error when creating your observation.");
      updateKeyCreateObservation("disabled", false);
    } else {
      resetForm();
      updateSnackbar({
        open: true,
        message: "Observation was saved successfully!",
        severity: "success",
      });
      navigate(`/view-obs/${success.observationCreate.observation.obsId}`);
    }
  };

  const [submitCreateObservation] = useMutation(OBSERVATION_CREATE, {
    context: {
      serializationKey: "MUTATION",
    },
    onCompleted: handleSuccess,
    onError: handleError,
  });

  const getFileType = (file) => {
    if (file.name) {
      return file.name.split(".").pop();
    } else if (file.type) {
      return file.type.split("/").pop();
    } else return "";
  };

  const formatFiles = (files) => {
    return files.map((f, index) => {
      if (f.originalFile && !f.originalFile.id) {
        const fileType = getFileType(f.originalFile);
        const newFile = new File(
          [f.originalFile],
          fileType !== ""
            ? `image_${f.associatedItem.id}_${index}.${fileType}`
            : `image_${f.associatedItem.id}_${index}`,
          {
            type: f.originalFile.type,
            size: f.originalFile.size,
          }
        );
        return newFile;
      }
    });
  };

  const getCreateObsInput = (form) => {
    const input = {
      submitter: Number(userData.currentUser.id),
      ownerSiteProject: Number(form.ospId),
      date: moment(form.date).format("YYYY-MM-DD"),
      time: moment(form.time).format("HH:mm:ss"),
      files: formatFiles(form.files),
      dollarAmount: Number(form.dollarAmount),
      correctionsGiven: !![
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
        ...form.unselectedMitigators.filter(
          (m) => m.isCorrection || m.isRootCause
        ),
      ].find((arm) => arm.isCorrection === true),
      raScore: form.raScore,
      raScoreMax: form.raScoreMax,
      raScoreCorr: form.raScoreCorr,
      raScoreMin: form.raScoreMin,
      manualCheck: form.outcomeUnplanned,
      users: form.foreman.map((user) => {
        return {
          id: Number(user.id),
        };
      }),
      crewMembers: form.crewMembers.map((user) => {
        return {
          id: Number(user.crewMember.id),
        };
      }),
      comment: form.correctionComments,
      outcomeExpected: form.outcomeExpected,
      outcomeInjury: form.outcomeInjury,
      outcomeNearMiss: form.outcomeNearMiss,
      contractor: Number(form.contractor.id),
      arms: [
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
        ...form.unselectedMitigators.filter(
          (m) => m.isCorrection || m.isRootCause
        ),
      ].map((arm) => {
        let obj = { id: Number(arm.id), isActive: arm.isActive };
        if (arm.isRootCause) {
          obj = {
            ...obj,
            isRootCause: arm.isRootCause,
          };
        }
        if (arm.isCorrection) {
          obj = {
            ...obj,
            isCorrection: arm.isCorrection,
          };
        }
        if (arm.comment) {
          obj = { ...obj, comment: arm.comment };
        }
        return obj;
      }),
      outcomes: form.outcomes.map((outcome) => {
        return { id: Number(outcome.id) };
      }),
      ...(form.ha?.haId && { ha: Number(form.ha.haId) }),
    };

    return input;
  };

  const executeSubmit = () => {
    if (userData?.currentUser) {
      const form = createObservationVar();
      const input = getCreateObsInput(form);

      submitCreateObservation({
        variables: {
          input,
        },
      });

      if (!online) {
        updateSnackbar({
          open: true,
          message:
            "Your device is offline. Your observation will be edited when back online.",
          severity: "info",
        });
      }
    } else onErrorFunc("Something went wrong, please refresh.");
  };

  return { executeSubmit };
};
