import React from "react";

import { useQuery } from "@apollo/client";

import { GET_SITES_ADMIN } from "../../../../../graphql/queries/admin/planning-site";
import TableTemplate from "../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";
import { filterData } from "./searchFilterFunction";

export default function Table({ data }) {
  const { data: sites, loading } = useQuery(GET_SITES_ADMIN, {
    fetchPolicy: "network-only",
  });

  const onActiveChange = (event) => {
    setActiveFilter(event.target.checked);
  };

  const onSiteOwnerChange = (event, newValue) => {
    setSiteOwnerFilter(newValue || { name: "" });
  };

  const clearFilters = () => {
    setActiveFilter(true);
    setSiteOwnerFilter({ name: "" });
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [active, setActiveFilter] = React.useState(true);
  const [siteOwner, setSiteOwnerFilter] = React.useState({ name: "" });

  const [filteredData, setFilteredData] = React.useState([]);

  // useEffect for filter update
  React.useEffect(() => {
    setFilteredData(
      filterData(
        sites ? sites.ownerSiteProjects : [],
        searchTerm,
        active,
        siteOwner
      )
    );
  }, [searchTerm, active, sites, siteOwner]);

  return (
    <TableTemplate
      hasPagination={true}
      data={filteredData}
      title="Sites"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Location",
          sort: true,
        },
        {
          uid: "timeCreated",
          numeric: true,
          disablePadding: false,
          label: "Date Created",
        },
        {
          uid: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
        },
        {
          uid: "action",
          numeric: false,
          disablePadding: false,
          label: "",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a.site[orderBy].toLowerCase();
          bItem = b.site[orderBy].toLowerCase();
        } else {
          aItem = a.site[orderBy];
          bItem = b.site[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          active={active}
          onActiveChange={onActiveChange}
          siteOwner={siteOwner}
          onSiteOwnerChange={onSiteOwnerChange}
          clearFilters={clearFilters}
        />
      )}
      searchTerm={searchTerm}
      updateSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} siteData={data} />}
      loading={loading}
    />
  );
}
