import { useMutation } from "@apollo/client";
import moment from "moment";

import { editObservationVar } from "../../../graphql/localVariables/observation";
import {
  authMutations,
  observationMutations,
} from "../../../graphql/mutations";
import { onErrorFunc } from "../../CustomComponents/OnErrorFunction";

export const useSubmitObservation = (id, online, resetForm) => {
  const { updateSnackbar } = authMutations;
  const { OBSERVATION_EDIT, updateKeyEditObservation } = observationMutations;

  const handleError = (error) => {
    updateKeyEditObservation("disabled", false);
    onErrorFunc(error);
  };

  const handleSuccess = (success) => {
    if (!success.observationEdit.observation) {
      handleError("There was an error when editing your observation.");
      updateKeyEditObservation("disabled", false);
    } else {
      resetForm();
      updateSnackbar({
        open: true,
        message: "Observation was saved successfully!",
        severity: "success",
      });
    }
  };

  const [submitEditObservation] = useMutation(OBSERVATION_EDIT, {
    context: {
      serializationKey: "MUTATION",
    },
    onCompleted: handleSuccess,
    onError: handleError,
  });

  const formatFiles = (files) => {
    return files.map((f, index) => {
      if (!f.originalFile.id) {
        const newName = f.originalFile.name.split(".").pop();
        const newFile = new File(
          [f.originalFile],
          `image_${f.associatedItem.id}_${index}.${newName}`,
          {
            type: f.originalFile.type,
          }
        );
        return newFile;
      }
    });
  };

  const getEditObsInput = (form) => {
    const input = {
      submitter: Number(form.submitter.id),
      ownerSiteProject: Number(form.ospId),
      date: moment(form.date).format("YYYY-MM-DD"),
      time: moment(form.time).format("HH:mm:ss"),
      correctionsGiven: [
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
        ...form.unselectedMitigators,
      ].find((arm) => arm.isCorrection === true)
        ? true
        : false,
      files: formatFiles(form.files),
      removeArmMediaFiles: form.removeMediaFiles
        ? form.removeMediaFiles.map((i) => Number(i))
        : [],
      removeMediaFiles: [],
      raScore: form.raScore,
      raScoreMax: form.raScoreMax,
      raScoreCorr: form.raScoreCorr,
      raScoreMin: form.raScoreMin,
      dollarAmount: Number(form.dollarAmount),
      manualCheck: form.outcomeUnplanned,
      contractor: Number(form.contractor.id),
      users: form.foreman.map((user) => {
        return {
          id: Number(user.id),
        };
      }),
      crewMembers: form.crewMembers.map((user) => {
        return {
          id: Number(user.crewMember.id),
        };
      }),
      comment: form.correctionComments,
      outcomeExpected: form.outcomeExpected,
      outcomeInjury: form.outcomeInjury,
      outcomeNearMiss: form.outcomeNearMiss,
      arms: [
        ...form.actions,
        ...form.risks,
        ...form.mitigators,
        ...form.unselectedMitigators,
      ].map((arm) => {
        return {
          id: Number(arm.id),
          isActive: arm.isActive,
          isCorrection: arm.isCorrection,
          isRootCause: arm.isRootCause,
        };
      }),
      outcomes: form.outcomes
        .filter(
          (o) =>
            ![
              "Non-Issue - Requiring Correction",
              "Safe",
              "Near Miss",
              "Unsafe Work Condition",
            ].includes(o.name)
        )
        .map((outcome) => {
          return { id: Number(outcome.id) };
        }),
      ...(form.ha && form.ha.haId && { ha: Number(form.ha.haId) }),
    };

    return input;
  };

  const executeSubmit = () => {
    const form = editObservationVar();
    const input = getEditObsInput(form);

    submitEditObservation({
      variables: {
        id: Number(id),
        input,
      },
    });

    if (!online) {
      updateSnackbar({
        open: true,
        message:
          "Your device is offline. Your observation will be edited when back online.",
        severity: "info",
      });
    }
  };

  return { executeSubmit };
};
