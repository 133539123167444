import React from "react";

import { Image, Link, Text, View } from "@react-pdf/renderer";
import PropTypes from "prop-types";

const width = "150px";

const IncidentImage = ({ img }) => {
  const [pngSrc, setPngSrc] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      const res = await fetch(img);
      const blob = await res.blob();

      // Convert image to PNG
      const imageBitmap = await createImageBitmap(blob);
      const canvas = document.createElement("canvas");
      canvas.width = imageBitmap.width;
      canvas.height = imageBitmap.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(imageBitmap, 0, 0);
      setPngSrc(canvas.toDataURL("image/png"));
    }
    fetchData();
  }, []);

  let style = { width, height: "auto", border: "1px solid white" };
  return <Image style={{ ...style, marginTop: "10px" }} src={pngSrc || img} />;
};

IncidentImage.propTypes = {
  img: PropTypes.string,
};

const getPreviewIcon = (fileObject) => {
  const fileName = fileObject?.file?.toLowerCase();

  if (
    fileName.includes("png") ||
    fileName.includes("jpg") ||
    fileName.includes("jpeg") ||
    fileName.includes("heic")
  ) {
    return (
      <Link
        key={fileObject.id}
        src={fileObject.file}
        style={{
          color: "white",
          fontSize: "14px",
          margin: "5px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width,
          minHeight: "150px",
        }}
      >
        <IncidentImage key={fileObject.id} img={fileObject.file} />
      </Link>
    );
  }

  return (
    <>
      {fileObject.file && (
        <Link
          key={fileObject.id}
          src={fileObject.file}
          style={{ color: "white", fontSize: "14px", margin: "10px" }}
        >
          <Text>
            {fileObject.file.split("/")[fileObject.file.split("/").length - 1]}
          </Text>
        </Link>
      )}
    </>
  );
};

export default function Responses({ id, i }) {
  const responses = i.incidentTopicResponses.find(
    (resp) => resp.incidentTopic.id === id
  );

  if (responses && (responses.note !== "" || responses.files.length > 0)) {
    return (
      <View style={{ width: "555px" }}>
        <View
          style={{
            marginBottom: "20px",
            backgroundColor: "#8297A0",
            color: "white",
            padding: "10px",
          }}
        >
          {responses.note !== "" && (
            <View>
              <Text style={{ fontSize: "14px", fontFamily: "Helvetica-Bold" }}>
                Note:
              </Text>
              <Text style={{ fontSize: "14px", margin: "10px 0px" }}>
                {responses.note}
              </Text>
            </View>
          )}
          {responses.files.length > 0 && (
            <View>
              <Text
                style={{
                  fontSize: "14px",
                  fontFamily: "Helvetica-Bold",
                  margin: "10px 0px",
                }}
                wrap={false}
              >
                Files:
              </Text>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {responses.files.map((f) => getPreviewIcon(f))}
              </View>
            </View>
          )}
        </View>
      </View>
    );
  }
  return null;
}

Responses.propTypes = {
  id: PropTypes.string,
  i: PropTypes.object,
};
