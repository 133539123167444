import React from "react";

import { Document, Page } from "@react-pdf/renderer";

import sortByKeys from "../../../../utils/sortByKeys";
import GeneralInformation from "./GeneralInformation";
import IncidentDetails from "./IncidentDetails";
import Topic from "./Topic";

const PDFDocument = (i) => {
  return (
    <Document
      pageMode="fullScreen"
      title="Incident Investigation"
      author="NIXN"
    >
      <GeneralInformation i={i} />
      <IncidentDetails i={i} />
      {sortByKeys(i.incident.incidentTopics, "ordering")
        .slice(2)
        .map((topic) => {
          if (
            topic.name === "Incident Classification - US (OSHA)" &&
            i.type !== "Injury"
          )
            return null;

          return (
            <Page size="A4" style={{ padding: 20 }} wrap key={topic.id}>
              <Topic topic={topic} i={i} />
            </Page>
          );
        })}
    </Document>
  );
};

export default PDFDocument;
