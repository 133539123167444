import React from "react";

import { Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";
import PropTypes from "prop-types";

import sortByKeys from "../../../../utils/sortByKeys";
import Topic from "./Topic";
import styles from "./styles";

const convertDate = (dateString) => {
  const date = new Date(dateString);
  const utc = date.getTime() + date.getTimezoneOffset() * 60000;
  const convertedDate = new Date(utc);

  return convertedDate;
};

export default function IncidentDetails({ i }) {
  const startTime = moment();
  const timeArr = i.time.split(":");
  startTime.hours(Number(timeArr[0])).minutes(Number(timeArr[1]));

  const injuryTime = moment();
  const injuryTimeArr = i.injuryTime.split(":");
  injuryTime.hours(Number(injuryTimeArr[0])).minutes(Number(injuryTimeArr[1]));

  const convertedDob = convertDate(i.employeeDob);

  const convertedIncidentDate = convertDate(i.injuryDate);

  const sortedTopics = sortByKeys(i.incident.incidentTopics, "ordering");

  return (
    <Page size="A4" style={{ padding: 20 }} wrap>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <View style={{ width: "100%" }}>
          <Text
            style={[
              styles.header,
              {
                fontSize: "16px",
                paddingBottom: "20px",
                borderTop: "3px solid grey",
                paddingTop: "10px",
              },
            ]}
          >
            INCIDENT DETAILS - US (OSHA)
          </Text>
        </View>

        <View style={styles.question}>
          <Text style={styles.header}>Employee Name</Text>
          <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
            {i.employeeName}
          </Text>
        </View>
        <View style={styles.question}>
          <Text style={styles.header}>Employee Job Title</Text>
          <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
            {i.employeeJobTitle}
          </Text>
        </View>
        <View style={styles.question}>
          <Text style={styles.header}>Employee Phone Number</Text>
          <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
            {i.employeePhoneNumber}
          </Text>
        </View>
        <View style={styles.question} wrap={false}>
          <Text style={styles.header}>Employee Date of Birth</Text>
          <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
            {moment(convertedDob).format("MM/DD/YYYY")}
          </Text>
        </View>
        <View style={styles.question} wrap={false}>
          <Text style={styles.header}>Incident Date/Time</Text>
          <Text style={{ fontSize: "14px", paddingVertical: "10px" }}>
            {moment(convertedIncidentDate).format("MM/DD/YYYY")}{" "}
            {injuryTime.format("hh:mm A")}
          </Text>
        </View>

        {/* INCIDENT DETAILS */}
        <Topic topic={sortedTopics[1]} i={i} />
      </View>
    </Page>
  );
}

IncidentDetails.propTypes = {
  i: PropTypes.object,
};
