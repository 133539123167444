import React from "react";

import { Autocomplete, TextField } from "@mui/material";

import { useQuery, useLazyQuery, useReactiveVar } from "@apollo/client";
import moment from "moment";

import {
  createObservationVar,
  editObservationVar,
} from "../../../../graphql/localVariables/observation";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { observationMutations } from "../../../../graphql/mutations";
import { GET_HA, GET_OBS_HAS } from "../../../../graphql/queries";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import removeDuplicates from "../../../../utils/removeDuplicates";
import sortByKeys from "../../../../utils/sortByKeys";

export default function HA({ type, onEdit }) {
  // Global Variables
  const editObs = useReactiveVar(editObservationVar);
  const createObs = useReactiveVar(createObservationVar);
  const form = type === "EDIT" ? editObs : createObs;
  const online = useReactiveVar(onlineVar);
  // Queries
  const { data: user } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: online ? "cache-first" : "cache-only",
  });
  const { data, loading } = useQuery(GET_OBS_HAS, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getHA, { data: ha }] = useLazyQuery(GET_HA, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const [HAs, setHAs] = React.useState([]);

  // Mutations
  const { updateKeyEditObservation, updateKeyCreateObservation } =
    observationMutations;
  const updateObsKeyFunction =
    type === "EDIT" ? updateKeyEditObservation : updateKeyCreateObservation;

  const onHAUpdate = (event, value) => {
    if (value) {
      updateObsKeyFunction("ha", value);
      const activeHaOsp = value.haOwnerSiteProject
        ? value.haOwnerSiteProject.filter((haOsp) => haOsp.isActive)
        : [];
      if (activeHaOsp.length > 0) {
        const osp = activeHaOsp[0].ownerSiteProject;
        updateObsKeyFunction("ospId", osp.id);
        updateObsKeyFunction("owner", osp.owner);
        updateObsKeyFunction("site", osp.site);
        updateObsKeyFunction(
          "location",
          `${osp.site.name} [${osp.owner.name}]`
        );
        if (value.haOwnerSiteProject.length === 1) {
          updateObsKeyFunction("project", osp.project);
        }
      } else {
        updateObsKeyFunction("ospId", null);
        updateObsKeyFunction("location", "");
        updateObsKeyFunction("owner", { name: "" });
        updateObsKeyFunction("site", { name: "" });
        updateObsKeyFunction("project", { name: "" });
        updateObsKeyFunction("contractor", { name: "" });
      }
      getHA({ variables: { id: Number(value.haId) } });
    } else {
      updateObsKeyFunction("ha", { name: "" });
    }
    onEdit();
  };

  React.useEffect(() => {
    if (ha) {
      updateObsKeyFunction("ha", ha.hazardAnalysis);
    }
  }, [ha]);

  React.useEffect(() => {
    if (data && data.hazardAnalyses && data.hazardAnalyses.edges && user) {
      let ha = sortByKeys(
        data.hazardAnalyses.edges.map((e) => e.node),
        "name"
      );

      // only show today's HAs if in specific classification
      if (
        user.currentUser.company.industry &&
        user.currentUser.company.industry.industrialClassification.length > 0 &&
        user.currentUser.company.industry.industrialClassification
          .map((ic) => ic.classification.name)
          .join(" ")
          .includes("Construction")
      ) {
        const categoryString =
          user.currentUser.company.industry.industrialClassification
            .map((ic) => ic.classification.name)
            .join(" ");

        if (
          categoryString.includes("Construction") ||
          categoryString.includes("Utilities") ||
          categoryString.includes(
            "Agriculture, Forestry, Fishing, and Hunting"
          ) ||
          categoryString.includes(
            "Mining, Quarrying, and Oil and Gas Extraction"
          ) ||
          categoryString.includes(
            "Professional, Scientific, and Technical Services"
          )
        ) {
          setHAs(
            removeDuplicates(
              ha.filter((ha) => {
                let haDateTime = new Date(ha.date);
                const utc =
                  haDateTime.getTime() + haDateTime.getTimezoneOffset() * 60000;
                haDateTime = new Date(utc);
                if (ha.time) {
                  const timeArr = ha.time.split(":");
                  haDateTime.setHours(Number(timeArr[0]));
                  haDateTime.setMinutes(Number(timeArr[1]));
                }

                return moment(haDateTime).isSameOrAfter(
                  moment().subtract(1, "days")
                );
              }),
              "haId"
            )
          );
        } else setHAs(ha);
      } else {
        setHAs(ha);
      }
    }
  }, [data, user]);

  return (
    <Autocomplete
      id="obs-select-ha"
      options={HAs.sort((a, b) => {
        if (a.date === b.date) return a.name > b.name ? 1 : -1;
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return aDate > bDate ? -1 : 1;
      })}
      getOptionLabel={(option) => {
        const d = new Date(option.date);
        const utc = d.getTime() + d.getTimezoneOffset() * 60000;
        const convertedDate = new Date(utc);

        return option.submitter
          ? `${option.name} [${option.submitter?.username}] - ${moment(
              convertedDate
            ).format("MM/DD/YYYY")}`
          : "";
      }}
      value={form.ha || { name: "", date: "" }}
      onChange={onHAUpdate}
      isOptionEqualToValue={(option, value) => option.haId === value.haId}
      renderOption={(props, option) => {
        const d = new Date(option.date);
        const utc = d.getTime() + d.getTimezoneOffset() * 60000;
        const convertedDate = new Date(utc);

        return (
          <li {...props} key={`${option.name}-${option.id}`}>
            {option.name} [{option.submitter?.username}] -{" "}
            {moment(convertedDate).format("MM/DD/YYYY")}
          </li>
        );
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label="Hazard Analysis:"
          value={params}
          id={params.id}
        />
      )}
    />
  );
}
