import * as React from "react";

import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Autocomplete,
  TextField,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import { useQuery, useReactiveVar } from "@apollo/client";
import PropTypes from "prop-types";

import { viewAuditFiltersVar } from "../../../../graphql/localVariables/audit";
import { onlineVar } from "../../../../graphql/localVariables/user";
import { auditMutations } from "../../../../graphql/mutations";
import { GET_SITES, GET_USERNAMES } from "../../../../graphql/queries";
import sortByKeys from "../../../../utils/sortByKeys";

export default function TableFilterDialog({
  open,
  setOpen,
  clearFilters,
  onFilter,
}) {
  const { updateKeyViewAuditFilters } = auditMutations;
  const filters = useReactiveVar(viewAuditFiltersVar);
  const online = useReactiveVar(onlineVar);

  const { data: sites } = useQuery(GET_SITES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const { data: usernames } = useQuery(GET_USERNAMES, {
    fetchPolicy: online ? "network-only" : "cache-only",
  });

  const filter = () => {
    onFilter();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="audit-filter-site"
              options={
                sites?.ownerSiteProjects
                  ? sortByKeys(sites.ownerSiteProjects, "site.name")
                  : []
              }
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={filters.site || { owner: { name: "" } }}
              onChange={(event, newValue) =>
                updateKeyViewAuditFilters(
                  "site",
                  newValue || { owner: { name: "" } }
                )
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.site
                      ? `${option.site?.name} [${option.owner?.name}]`
                      : option.owner?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="audit-filter-users"
              options={
                usernames?.users ? sortByKeys(usernames.users, "username") : []
              }
              getOptionLabel={(option) => {
                return option.username;
              }}
              value={filters.usernames || []}
              multiple
              limitTags={4}
              disableCloseOnSelect
              onChange={(event, newValue) =>
                updateKeyViewAuditFilters("usernames", newValue || [])
              }
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank />}
                    checkedIcon={<CheckBox />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.username}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Submitter"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.afterDate || null}
              onChange={(date) => updateKeyViewAuditFilters("afterDate", date)}
              renderInput={(params) => (
                <TextField fullWidth variant="standard" {...params} />
              )}
              disableMaskedInput
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.beforeDate || null}
              onChange={(date) => updateKeyViewAuditFilters("beforeDate", date)}
              renderInput={(params) => (
                <TextField fullWidth variant="standard" {...params} />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={filter} variant="contained" color="secondary">
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};
