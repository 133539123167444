import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  Autocomplete,
  TextField,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import PropTypes from "prop-types";

import sortByKeys from "../../../../../../../utils/sortByKeys";

export default function TableFilterDialog({
  open,
  setOpen,
  filters,
  setFilters,
  clearFilters,
  onFilter,
  ownerSiteProjects,
}) {
  const filter = () => {
    onFilter();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              id="audit-filter-site"
              options={
                ownerSiteProjects
                  ? sortByKeys(ownerSiteProjects, "site.name")
                  : []
              }
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={filters.site || { owner: { name: "" } }}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  site: newValue || { owner: { name: "" } },
                }))
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.site
                      ? `${option.site?.name} [${option.owner?.name}]`
                      : option.owner?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                  value={params}
                  id={params.id}
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.afterDate || null}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, afterDate: date }))
              }
              renderInput={(params) => (
                <TextField variant="standard" {...params} fullWidth />
              )}
              disableMaskedInput
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.beforeDate || null}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, beforeDate: date }))
              }
              renderInput={(params) => (
                <TextField variant="standard" {...params} fullWidth />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={filter} variant="contained" color="secondary">
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
};
