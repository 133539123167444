import React from "react";

import { useLazyQuery } from "@apollo/client";

import { GET_PAGINATED_TEMPLATES } from "../../../../../graphql/queries/admin/planning-template";
import TableTemplate from "../../../../CustomComponents/TableTemplate/TableTemplate";
import Row from "./Row";
import TableFilterDialog from "./TableFilterDialog";

export default function Table() {
  const [getTemplates, { data, loading, refetch, fetchMore }] = useLazyQuery(
    GET_PAGINATED_TEMPLATES,
    {
      notifyOnNetworkStatusChange: true,
    }
  );
  const initialState = {
    searchterm: "",
    isActive: true,
    afterDate: null,
    beforeDate: null,
  };
  const [filters, setFilters] = React.useState(initialState);
  const [initialLoad, setInitialLoad] = React.useState(false);

  const getInput = () => {
    const input = {
      first: 50,
      isActive: filters.isActive,
      fName: filters.searchterm,
    };

    if (filters.afterDate) {
      input.fAfterDate = filters.afterDate.format("YYYY-MM-DD");
    } else {
      input.fAfterDate = null;
    }
    if (filters.beforeDate) {
      input.fBeforeDate = filters.beforeDate.format("YYYY-MM-DD");
    } else {
      input.fBeforeDate = null;
    }

    return input;
  };

  const onFilter = () => {
    refetch(getInput());
  };

  React.useEffect(() => {
    getTemplates({
      variables: { first: 50, ...getInput() },
      onCompleted() {
        setInitialLoad(true);
      },
    });
  }, []);

  React.useEffect(() => {
    if (initialLoad) {
      onFilter();
    }
  }, [filters.searchterm]);

  return (
    <TableTemplate
      hasPagination={true}
      data={
        data?.paginatedTemplates?.edges
          ? data.paginatedTemplates.edges.map((e) => e.node)
          : []
      }
      handleFetchMore={(page, rowsPerPage) => {
        if (
          data?.paginatedTemplates?.edges &&
          (page + 1) * rowsPerPage + 1 > data.paginatedTemplates.edges.length &&
          data.paginatedTemplates?.pageInfo?.hasNextPage
        ) {
          fetchMore({
            variables: {
              after: data.paginatedTemplates.pageInfo.endCursor,
              first: 50, // Fetch the next 50 items
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
              if (!fetchMoreResult) return previousResult;
              return {
                paginatedTemplates: {
                  ...fetchMoreResult.paginatedTemplates,
                  edges: [
                    ...previousResult.paginatedTemplates.edges,
                    ...fetchMoreResult.paginatedTemplates.edges,
                  ],
                },
              };
            },
          });
        }
      }}
      paginationCount={
        data?.paginatedTemplates?.totalCount
          ? data.paginatedTemplates.totalCount
          : 0
      }
      title="Templates"
      headCells={[
        {
          uid: "name",
          numeric: false,
          disablePadding: false,
          label: "Name",
          sort: true,
        },
        {
          uid: "startDate",
          numeric: false,
          disablePadding: false,
          label: "Start Date",
        },
        {
          uid: "endDate",
          numeric: false,
          disablePadding: false,
          label: "End Date",
        },
        {
          uid: "timeCreated",
          numeric: false,
          disablePadding: false,
          label: "Date Created",
        },
        {
          uid: "lastUpdated",
          numeric: false,
          disablePadding: false,
          label: "Last Updated",
        },
        {
          uid: "editor",
          numeric: false,
          disablePadding: false,
          label: "Last Editor",
        },
        {
          uid: "revision",
          numeric: false,
          disablePadding: false,
          label: "Revision #",
        },
        {
          uid: "associatedTo",
          numeric: false,
          disablePadding: false,
          label: "Associated To",
        },
        {
          uid: "active",
          numeric: false,
          disablePadding: false,
          label: "Active",
        },
        {
          uid: "",
          numeric: false,
          disablePadding: false,
          label: "",
        },
      ]}
      initialOrderBy="name"
      getItemsFromOrderBy={(a, b, orderBy) => {
        let aItem, bItem;

        if (orderBy === "name") {
          aItem = a[orderBy].toLowerCase();
          bItem = b[orderBy].toLowerCase();
        } else {
          aItem = a[orderBy];
          bItem = b[orderBy];
        }

        return { aItem, bItem };
      }}
      renderFilterDialog={(open, setOpen, setPage) => (
        <TableFilterDialog
          open={open}
          setOpen={setOpen}
          filters={filters}
          setFilters={setFilters}
          clearFilters={() => {
            setFilters(initialState);
            refetch({
              first: 50,
              isActive: true,
              fBeforeDate: null,
              fAfterDate: null,
              fName: "",
            });
            setPage(0);
            setOpen(false);
          }}
          handleFilter={() => {
            refetch(getInput());
            setPage(0);
          }}
        />
      )}
      searchTerm={filters.searchterm}
      updateSearchTerm={(searchterm) =>
        setFilters((prev) => ({ ...prev, searchterm }))
      }
      getBadgeCount={() => {}}
      renderRow={(row) => <Row key={row.id} row={row} onFilter={onFilter} />}
      loading={loading}
    />
  );
}
