import { gql } from "@apollo/client";

export const GET_TEMPLATES = gql`
  query adminTemplates {
    templates {
      id
      isActive
      name
      timeCreated
      lastUpdated
      lastEditor {
        id
        firstName
        lastName
        username
      }
      revisionNumber
      startDate
      endDate
      ownerSiteProjectTemplates(isActive: true) {
        isActive
        id
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_PAGINATED_TEMPLATES = gql`
  query paginatedTemplates(
    $first: Int
    $after: String
    $fName: String
    $fAfterDate: Date
    $fBeforeDate: Date
    $isActive: Boolean
  ) {
    paginatedTemplates(
      first: $first
      after: $after
      fName: $fName
      startDate_Gte: $fAfterDate
      endDate_Lte: $fBeforeDate
      isActive: $isActive
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          isActive
          name
          timeCreated
          lastUpdated
          lastEditor {
            id
            firstName
            lastName
            username
          }
          revisionNumber
          startDate
          endDate
          ownerSiteProjectTemplates(isActive: true) {
            isActive
            id
            ownerSiteProject {
              id
              owner {
                id
                name
              }
              site {
                id
                name
              }
              project {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_TEMPLATE_DATA = gql`
  query adminTemplate($id: Int) {
    template(id: $id) {
      id
      isActive
      name
      timeCreated
      lastUpdated
      lastEditor {
        id
        firstName
        lastName
        username
      }
      revisionNumber
      startDate
      endDate
    }

    ownerSiteProjectTemplates(template_Id: $id, isActive: true) {
      id
      ownerSiteProject {
        id
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
      }
    }

    templateArms(isActive: true, template_Id: $id) {
      id
      isActive
      arm {
        id
        a {
          id
          name
        }
        r {
          id
          name
        }
        m {
          id
          name
        }
      }
    }
  }
`;

export const GET_TEMPLATE_OSP = gql`
  query templateOSP($id: Int) {
    ownerSiteProjectTemplates(template_Id: $id, isActive: true) {
      id
      ownerSiteProject {
        id
        owner {
          id
          name
        }
        site {
          id
          name
        }
        project {
          id
          name
        }
      }
    }
  }
`;

export const GET_OSPS = gql`
  query adminOwnerSiteProject {
    ownerSiteProjects(isActive: true) {
      id
      project {
        id
        name
      }
      site {
        id
        name
      }
      owner {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_OSP = gql`
  query companyOwnerSiteProject {
    ownerSiteProjects(site_Isnull: true, isActive: true) {
      id
      owner {
        id
        name
      }
    }
  }
`;

// Return owner-wide, site-wide and project-wide OSPs for the create Template page
export const GET_TEMPLATE_PAGE_OSPS = gql`
  query templatePageOsps {
    templatePageOsps {
      id
      project {
        id
        name
      }
      site {
        id
        name
      }
      owner {
        id
        name
      }
    }
  }
`;
