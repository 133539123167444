import React from "react";
import { Virtuoso } from "react-virtuoso";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import sortByGetFunction from "../../../../utils/sortByGetFunction";
import { CustomTextField } from "../../../CustomStyles/LightTextField";

export default function SelectedUnselected({
  selected,
  unselected,
  setSelected,
  setUnselected,
  getName,
  label,
  selectedLabel,
  showSearch,
  loadingUnselected,
}) {
  const [searching, setSearching] = React.useState("");
  const [search, setSearch] = React.useState("");

  const timer = React.useRef(null);

  React.useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setSearch(searching);
    }, 1000);
  }, [searching]);

  const select = (item) => {
    const stateSelected = [...selected, item];
    const oldStateUnselected = [...unselected];
    const newState = oldStateUnselected.filter(
      (prevItems) => prevItems.id !== item.id
    );
    setSelected(stateSelected);
    setUnselected(newState);
  };

  const unselect = (item) => {
    const stateUnselected = [...unselected, item];
    const oldStateSelected = [...selected];
    const newState = oldStateSelected.filter(
      (prevItems) => prevItems.id !== item.id
    );
    setSelected(newState);
    setUnselected(stateUnselected);
  };

  const getSearchedItems = (list) => {
    if (search === "") return list;
    else {
      return list.filter((l) =>
        getName(l).toLowerCase().includes(search.toLowerCase())
      );
    }
  };

  const getUnselectedRow = (index, item) => {
    return (
      <Grid key={`${getName(item)}-${index}`}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <Grid item xs={10}>
            <Typography style={{ maxWidth: "90%" }}>{getName(item)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => select(item)}>
              <AddIcon
                style={{
                  color: "#FDBC02",
                  fontSize: "24px",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getSelectedRow = (index, item) => {
    return (
      <Grid onClick={() => unselect(item)} key={`${getName(item)}-${index}`}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{
            backgroundColor: "white",
            padding: "10px",
          }}
        >
          <Grid item xs={10}>
            <Typography style={{ maxWidth: "90%" }}>{getName(item)}</Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton>
              <CloseIcon
                style={{
                  color: "#FDBC02",
                  fontSize: "24px",
                  cursor: "pointer",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography style={{ fontSize: "0.8rem" }}>
              Unselected {label}:
            </Typography>
          </Grid>
          {showSearch && (
            <CustomTextField
              value={searching}
              onChange={(e) => setSearching(e.target.value)}
              variant="standard"
              label={`Search ${label}:`}
              fullWidth
              sx={{ mb: 2 }}
            />
          )}
          <div
            style={{
              border: "1px solid #8297a0",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "black",
              maxHeight: 250,
            }}
          >
            {loadingUnselected && (
              <Grid container justifyContent="center" sx={{ p: 2 }}>
                <CircularProgress color="secondary" />
              </Grid>
            )}
            {getSearchedItems(unselected).length === 0 ? (
              <Grid sx={{ p: 1 }}>
                <Typography>None unselected</Typography>
              </Grid>
            ) : (
              <Virtuoso
                style={{ height: 250 }}
                data={sortByGetFunction(getSearchedItems(unselected), getName)}
                itemContent={(index, item) => getUnselectedRow(index, item)}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontSize: "0.8rem", mb: 2 }}>
            {selectedLabel
              ? "Selected"
              : label === "Actions"
              ? "Observed"
              : "Present"}{" "}
            {label}:
          </Typography>
          <div
            style={{
              border: "1px solid #8297a0",
              borderRadius: "4px",
              backgroundColor: "white",
              color: "black",
              maxHeight: 250,
            }}
          >
            {selected.length === 0 ? (
              <Grid sx={{ p: 1 }}>
                <Typography>None selected</Typography>
              </Grid>
            ) : (
              <Virtuoso
                style={{ height: 250 }}
                data={sortByGetFunction(selected, getName)}
                itemContent={(index, item) => getSelectedRow(index, item)}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}
