import React from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
  Checkbox,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import PropTypes from "prop-types";

import sortByKeys from "../../../../../../../utils/sortByKeys";

export default function TableFilterDialog({
  open,
  setOpen,
  filters,
  setFilters,
  handleFilter,
  clearFilters,
  sites,
  projects,
  templates,
}) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const filterProjects = () => {
    if (projects && filters.site.owner.name !== "") {
      return projects
        .filter((p) => Number(p.site.id) === Number(filters.site.site.id))
        .map((p) => p.project);
    }
    return projects ? projects.map((p) => p.project) : [];
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="ha-filter-site"
              options={sites ? sortByKeys(sites, "site.name") : []}
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={filters.site}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  site: newValue || { owner: { name: "" } },
                }))
              }
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.site
                      ? `${option.site?.name} [${option.owner?.name}]`
                      : option.owner?.name}
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="filter-projects"
              options={sortByKeys(filterProjects(), "name")}
              value={filters.projects}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={option.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              multiple
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  projects: newValue || [],
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={"Filter by Projects"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="filter-templates"
              options={templates ? sortByKeys(templates, "name") : []}
              value={filters.templates}
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              )}
              multiple
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  templates: newValue || [],
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={"Filter by Templates"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="filter-status"
              options={["Open", "Closed"]}
              value={filters.status}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  site: newValue || "",
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Status"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="filter-incident"
              options={["With", "Without"]}
              value={filters.incident}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  incident: newValue || "",
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Incident"
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.afterDate}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  afterDate: date,
                }))
              }
              renderInput={(params) => (
                <TextField fullWidth variant="standard" {...params} />
              )}
              disableMaskedInput
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.beforeDate}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  beforeDate: date,
                }))
              }
              renderInput={(params) => (
                <TextField fullWidth variant="standard" {...params} />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleFilter();
            setOpen(false);
          }}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
