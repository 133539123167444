import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  Grid,
  Button,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import PropTypes from "prop-types";

import sortByKeys from "../../../../../../../utils/sortByKeys";

export default function TableFilterDialog({
  open,
  setOpen,
  filters,
  setFilters,
  handleFilter,
  clearFilters,
  sites,
  outcomes,
  contractors,
}) {
  const filter = () => {
    handleFilter();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="filter-site"
              options={sites ? sortByKeys(sites, "site.name") : []}
              getOptionLabel={(option) =>
                option.site
                  ? `${option.site?.name} [${option.owner?.name}]`
                  : option.owner?.name
              }
              value={filters.site}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  site: newValue || { owner: { name: "" } },
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Site [Site Owner]"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="filter-contractor"
              options={contractors ? sortByKeys(contractors, "name") : []}
              getOptionLabel={(option) => option.name}
              value={filters.contractor}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  contractor: newValue || { name: "" },
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Contractor"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="filter-outcomes"
              options={outcomes ? sortByKeys(outcomes, "name") : []}
              getOptionLabel={(option) => option.name}
              value={filters.outcomes}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  outcomes: newValue || [],
                }))
              }
              multiple
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Outcome"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="filter-planned"
              options={["Planned", "Unplanned"]}
              getOptionLabel={(option) => option}
              value={filters.status}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  status: newValue || "",
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Planned Status"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.afterDate}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  afterDate: date,
                }))
              }
              renderInput={(params) => (
                <TextField fullWidth variant="standard" {...params} />
              )}
              disableMaskedInput
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.beforeDate}
              onChange={(date) =>
                setFilters((prev) => ({
                  ...prev,
                  beforeDate: date,
                }))
              }
              renderInput={(params) => (
                <TextField fullWidth variant="standard" {...params} />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={filter} variant="contained" color="secondary">
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
