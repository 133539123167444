import { gql } from "@apollo/client";

export const GET_SIMPLE_COMPANIES = gql`
  query activeCompanies {
    companies(isActive: true) {
      id
      name
      logoBase64
    }
  }
`;

export const GET_COMPANY_ADMIN_SELECT = gql`
  query activeCompanies {
    companies(isActive: true, manage: true) {
      id
      name
      logoBase64
    }
  }
`;

export const GET_COMPANIES = gql`
  query companies {
    companies(isActive: true) {
      id
      name
      isActive
      ownerSiteProjectContractor {
        contractor {
          id
        }
      }
      isContractor @client
    }
  }
`;

export const COMPANY_DASHBOARD = gql`
  query companyDashboard {
    companies(isDashboard: true) {
      id
      name
      isActive
      logoBase64
      ownerSiteProjectContractor {
        contractor {
          id
        }
      }
      isContractor @client
    }
  }
`;

export const GET_COMPANY = gql`
  query company($id: Int) {
    company(id: $id) {
      id
      isActive
      industry {
        id
        code
        name
        industrialClassifications(isActive: true) {
          classification {
            id
            name
          }
        }
      }
      name
      riskThreshold
      logoBase64
      administrativeOrganization
      parent {
        id
        name
      }
      child {
        id
        name
      }
    }
  }
`;

export const GET_PROJECT_DATA_COMPANY = gql`
  query companyProjectData($projectId: Int) {
    project(id: $projectId) {
      id
      name
      timeCreated
      isActive
      ownerSiteProjects(site_Isnull: false, isActive: true) {
        id
        location @client
        owner {
          id
          name
        }
        site {
          id
          name
        }
      }
    }
  }
`;

export const GET_CREWMEMBER_DATA_COMPANY = gql`
  query companyCrewmemberData($crewId: Int) {
    crewMember(id: $crewId) {
      id
      firstName
      lastName
      phoneNumber
      email
      hireDate
      other
      company {
        name
      }
      yrsOfExp {
        id
        name
      }
      drugTestExpiry
      jobTitle {
        id
        name
      }
      craftType {
        id
        name
      }
      crewMemberContacts(isActive: true) {
        id
        contact {
          id
          name
          phoneNumber
        }
      }
      haCrewMember {
        ha {
          id
          haId
          name
          date
          raScore
          isCompleted
          hasInjury
          revisionNumber
          submitter {
            username
            company {
              name
            }
          }
          haCrewMembers {
            id
          }
          haTemplate {
            template {
              name
            }
          }
          haOwnerSiteProject {
            ownerSiteProject {
              owner {
                name
              }
              site {
                name
              }
              project {
                name
              }
            }
          }
        }
      }
      ownerSiteProjectCrewMembers(
        isActive: true
        ownerSiteProject_IsActive: true
        ownerSiteProject_Project_Isnull: true
      ) {
        id
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_SITES = gql`
  query sites {
    sites {
      id
      name
      ownerSiteProjects {
        owner {
          id
          name
        }
        project {
          id
        }
      }
      location @client
      ownerId @client
    }
  }
`;

export const GET_OSPs = gql`
  query ownerSiteProject {
    ownerSiteProjects {
      id
      isActive
      project {
        id
        name
      }
      site {
        id
        name
      }
      owner {
        id
        name
      }
      projectId @client
    }
  }
`;

export const GET_USERS = gql`
  query activeUsers {
    users(isActive: true) {
      username
      firstName
      lastName
      id
      image
      company {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_USERS = gql`
  query companyUsers($companyId: Int) {
    users(company_Id: $companyId, isActive: true) {
      isActive
      username
      firstName
      lastName
      id
      image
      email
      company {
        id
        name
      }
      supervise {
        id
        supervisee {
          id
          firstName
          lastName
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
      userPermissionGroups(isActive: true) {
        id
        permissionGroup {
          id
          name
          permissionGroupPermission {
            id
            permission {
              id
              name
              description
            }
          }
        }
      }
      supervise {
        id
        supervisee {
          id
          firstName
          lastName
          username
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
      ha {
        edges {
          node {
            haId
            id
            name
          }
        }
      }
      observation {
        edges {
          node {
            obsId
            id
            name
          }
        }
      }
      auditSubmission {
        id
        audit {
          name
        }
        date
        time
      }
    }
  }
`;

export const GET_COMPANY_USERS_SIMPLE = gql`
  query companyUsers($companyId: Int) {
    users(company_Id: $companyId, isActive: true) {
      isActive
      username
      firstName
      lastName
      id
      image
      email
      company {
        id
        name
      }
    }
  }
`;

export const GET_COMPANY_USER_DATA = gql`
  query companyUser($id: Int) {
    user(id: $id) {
      username
      firstName
      lastName
      id
      company {
        id
        name
      }
      userPermissionGroups(isActive: true) {
        id
        permissionGroup {
          id
          name
          permissionGroupPermissions {
            permission {
              id
              name
              description
            }
          }
        }
      }
      supervisedBy {
        isActive
        supervisor {
          id
          username
          firstName
          lastName
        }
      }
      supervise {
        id
        isActive
        supervisee {
          id
          firstName
          lastName
          username
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
      ha {
        edges {
          node {
            id
            name
            date
            submitter {
              username
            }
            haOwnerSiteProject {
              ownerSiteProject {
                id
                owner {
                  id
                  name
                }
                site {
                  id
                  name
                }
                project {
                  id
                  name
                }
              }
            }
            haTemplate {
              template {
                id
                name
              }
            }
            isCompleted
            revisionNumber
            observation {
              edges {
                node {
                  obsId
                }
              }
            }
            haCrewMembers {
              id
            }
            raScore
          }
        }
      }
      observation {
        edges {
          node {
            obsId
            id
            name
            date
            time
            submitter {
              id
              username
            }
            contractor {
              id
              name
            }
            ha {
              name
              date
            }
            ownerSiteProject {
              id
              owner {
                id
                name
              }
              site {
                id
                name
              }
            }
            observationOutcomes(isActive: true) {
              outcome {
                name
              }
            }
          }
        }
      }
      auditSubmission {
        id
        audit {
          name
        }
        date
        time
        timeCreated
        submitter {
          id
          username
        }
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
        }
      }
      ownerSiteProjectUsers(
        ownerSiteProject_Site_Isnull: false
        ownerSiteProject_Project_Isnull: true
        isActive: true
      ) {
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            name
            id
            location @client
          }
          project {
            name
            id
          }
        }
      }
    }
  }
`;

export const GET_ACTIVE_INDUSTRIES = gql`
  query industries {
    classifications(isActive: true) {
      id
      isActive
      name
      industrialClassifications {
        isActive
        industry {
          id
          name
          code
          isActive
        }
      }
    }
  }
`;

export const GET_ALL_INDUSTRIES = gql`
  query industries {
    classifications {
      id
      isActive
      name
      industrialClassifications {
        isActive
        industry {
          id
          name
          code
          isActive
        }
      }
    }
  }
`;

export const GET_COMPANY_SITES = gql`
  query getCompanySites {
    ownerSiteProjects(
      manage: true
      site_Isnull: false
      project_Isnull: true
      site_IsActive: true
      isActive: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
        location @client
      }
    }
  }
`;

export const GET_OWNER_SITES = gql`
  query getOwnerSites {
    ownerSiteProjects(
      isActive: true
      owner_IsActive: true
      site_Isnull: false
      project_Isnull: true
      site_IsActive: true
    ) {
      id
      owner {
        id
        name
      }
      site {
        id
        name
      }
    }
  }
`;

export const CREW_MEMBER_INFO = gql`
  query crewMemberInfo {
    yearsOfExps(isActive: true) {
      id
      name
    }
    jobTitles(isActive: true) {
      id
      name
    }
    craftTypes(isActive: true) {
      id
      name
    }
    contacts(isActive: true) {
      id
      name
      phoneNumber
    }
  }
`;

export const GET_CONTRACTOR_INFO = gql`
  query contractorInfo($ownerId: Int, $contractorId: Int) {
    company(id: $contractorId) {
      id
      name
      ownerSiteProjectContractors(ownerSiteProject_Owner_Id: $ownerId) {
        id
        ownerSiteProject {
          id
          owner {
            id
            name
          }
          site {
            id
            name
          }
          project {
            id
            name
          }
        }
      }
    }
  }
`;
