import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";

import { initialState } from "../../../graphql/localVariables/observation";
import { onlineVar } from "../../../graphql/localVariables/user";
import {
  authMutations,
  observationMutations,
} from "../../../graphql/mutations";
import { GET_COMPANY_ARMS } from "../../../graphql/queries";
import { GET_SINGLE_OBSERVATION } from "../../../graphql/queries/observations";
import removeDuplicates from "../../../utils/removeDuplicates";
import Layout from "../../Layout";
import ObservationForm from "../Form";
import { useSubmitObservation } from "./useSubmitObservation";

export default function EditObservation() {
  const { id } = useParams();
  const navigate = useNavigate();

  // Queries
  const online = useReactiveVar(onlineVar);
  const {
    data: observation,
    refetch,
    loading: obsLoading,
  } = useQuery(GET_SINGLE_OBSERVATION, {
    variables: { id: Number(id) },
    fetchPolicy: online ? "network-only" : "cache-only",
  });
  const [getCompanyMit, { data: mit, loading: loadingMit }] = useLazyQuery(
    GET_COMPANY_ARMS,
    {
      fetchPolicy: online ? "network-only" : "cache-only",
    }
  );
  // Local Variables
  const [expanded, setExpanded] = React.useState(1);
  const [currentSection, setCurrentSection] = React.useState(6);

  // Clear Form
  const resetForm = () => {
    setCurrentSection(1);
    setExpanded(false);
    updateAllEditObservation({
      id: null,
      ...initialState,
    });
    navigate(`/view-obs/${id}`);
  };

  const { executeSubmit } = useSubmitObservation(id, online, resetForm);

  // Mutations
  const { updateLoading } = authMutations;
  const { updateAllEditObservation } = observationMutations;

  const findSection = (arm) => {
    if (arm.arm.a && arm.arm.r === null) {
      return 2;
    } else if (arm.arm.r && arm.arm.m === null) {
      return 3;
    } else if (arm.arm.m && arm.isActive) {
      return 4;
    } else if (arm.isCorrection) {
      return 5;
    }
  };

  // If id in url updates, refetch query
  React.useEffect(() => {
    refetch({ id: Number(id) });
  }, [id]);

  React.useEffect(() => {
    if (observation?.observation) {
      const o = observation.observation;
      const companyId = Number(o.submitter.company.id);

      getCompanyMit({ variables: { companyId, rNull: false, mNull: false } });
    }
  }, [observation]);

  React.useEffect(() => {
    // Update the global var to update with obs data
    if (observation?.observation && mit) {
      const o = observation.observation;
      let armMediaArray = [];

      const formattedArms = o.observationArms.map((arm) => {
        if (arm.observationArmMediaFiles.length !== 0) {
          arm.observationArmMediaFiles.map((armFile) => {
            armMediaArray.push({
              associatedItem: {
                id: armFile.observationArm.arm.id,
                isExpected: armFile.observationArm.arm.isExpected,
                isCorrection: armFile.observationArm.isCorrection,
                isActive: armFile.observationArm.isActive,
                isRootCause: armFile.observationArm.isRootCause,
                a: armFile.observationArm.arm.a,
                r: armFile.observationArm.arm.r,
                m: armFile.observationArm.arm.m,
              },
              originalFile: armFile.mediaFile,
              section: findSection(armFile.observationArm),
            });
          });
        }

        return {
          id: arm.arm.id,
          isExpected: arm.arm.isExpected,
          isCorrection: arm.isCorrection,
          isActive: arm.isActive,
          isRootCause: arm.isRootCause,
          a: arm.arm.a,
          r: arm.arm.r,
          m: arm.arm.m,
        };
      });
      const risks = removeDuplicates(
        formattedArms.filter((arm) => arm.r),
        "r",
        "id"
      ).filter((arm) => arm.r);
      const mitigators = formattedArms.filter((arm) => arm.m && arm.isActive);
      const formattedCompanyArms = mit.companyArms.map((carm) => carm.arm);
      const corrections = formattedArms.filter((c) => c.isCorrection);
      const nonCorrectionUnselectedMitigators = formattedCompanyArms
        .filter(
          (mitigatorArm) =>
            // associated to added risk
            risks.findIndex(
              (riskArm) =>
                Number(riskArm.a.id) === Number(mitigatorArm.a.id) &&
                Number(riskArm.r.id) === Number(mitigatorArm.r.id)
            ) >= 0 &&
            // NOT added mitigator
            mitigators.findIndex(
              (riskArm) =>
                Number(riskArm.a.id) === Number(mitigatorArm.a.id) &&
                Number(riskArm.r.id) === Number(mitigatorArm.r.id) &&
                Number(riskArm.m.id) === Number(mitigatorArm.m.id)
            ) < 0 &&
            // NOT added correction
            corrections.findIndex(
              (correctionArm) =>
                Number(correctionArm.a.id) === Number(mitigatorArm.a.id) &&
                Number(correctionArm.r.id) === Number(mitigatorArm.r.id) &&
                Number(correctionArm.m.id) === Number(mitigatorArm.m.id)
            ) < 0
        )
        .map((arm) => ({ ...arm, isActive: false }));
      const unselectedMitigators = [
        ...corrections,
        ...nonCorrectionUnselectedMitigators,
      ];

      updateAllEditObservation({
        id,
        submitter: o.submitter,
        owner: o.ownerSiteProject.owner,
        site: o.ownerSiteProject.site,
        project: o.ownerSiteProject.project,
        ospId: o.ownerSiteProject.id,
        location: `${o?.ownerSiteProject.site.name} [${o?.ownerSiteProject.owner.name}]`,
        contractor: o.contractor,
        correctionComments: o.comment,
        crewMembers: o.observationCrewMembers,
        foreman: o.observationUsers.map((u) => u.user),
        date: new Date(`${o.date} ${o.time}`),
        time: new Date(`${o.date} ${o.time}`),
        ha: o.ha,
        actions: removeDuplicates(
          formattedArms.filter((arm) => arm.a),
          "a",
          "id"
        ),
        risks,
        mitigators,
        unselectedMitigators,
        corrections,
        outcomeUnplanned: o.automaticCheck,
        outcomeExpected: o.outcomeExpected,
        dollarAmount: o.dollarAmount,
        outcomeInjury: o.outcomeInjury,
        outcomes: o.observationOutcomes.map((o) => ({
          id: o.outcome.id,
          name: o.outcome.name,
          checked: true,
        })),
        outcomeNearMiss: o.outcomeNearMiss,
        files: armMediaArray,
        raScore: o.raScore,
        raScoreMax: o.raScoreMax,
        raScoreCorr: o.raScoreCorr,
        raScoreMin: o.raScoreMin,
      });
    }
  }, [observation, mit]);

  React.useEffect(() => {
    updateLoading(obsLoading || loadingMit);
  }, [obsLoading, loadingMit]);

  if (observation?.observation === null) {
    return (
      <Layout>
        <Typography sx={{ color: "#fff" }}>
          No observation could be found.
        </Typography>
      </Layout>
    );
  }
  return (
    <Layout>
      <ObservationForm
        type={"EDIT"}
        executeSubmit={executeSubmit}
        resetForm={resetForm}
        expanded={expanded}
        setExpanded={setExpanded}
        setCurrentSection={setCurrentSection}
        currentSection={currentSection}
      />
    </Layout>
  );
}
