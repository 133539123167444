import React from "react";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";

import { TEMPLATE_CREATE } from "../../../../graphql/mutations/admin/planning-template";
import { GET_COMPANY_ARMS_ADMIN } from "../../../../graphql/queries/admin/arm";
import { GET_COMPANY } from "../../../../graphql/queries/admin/company";
import { GET_TEMPLATES } from "../../../../graphql/queries/admin/planning-template";
import { GET_CURRENT_USER } from "../../../../graphql/queries/auth";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import SelectOwnerSiteProject from "./SelectOwnerSiteProject";
import SelectedUnselected from "./SelectedUnselected";

export default function CreateTemplate({ open, handleClose, company }) {
  const { data: user } = useQuery(GET_CURRENT_USER);
  const [getCompanyActions, { data: actions }] = useLazyQuery(
    GET_COMPANY_ARMS_ADMIN,
    {
      fetchPolicy: "network-only",
    }
  );

  React.useEffect(() => {
    if (open && user) {
      const companyId = Number(user.currentUser.company.id);

      getCompanyActions({
        variables: {
          companyId,
          rNull: true,
          mNull: true,
        },
      });
    }
  }, [open, user]);

  const [createTemplate, { loading }] = useMutation(TEMPLATE_CREATE, {
    onCompleted() {
      handleClose();
      onClear();
      onCompletedFunc("Template has been created.");
    },
    onError(error) {
      onErrorFunc(error);
    },
    refetchQueries: [
      { query: GET_TEMPLATES, fetchPolicy: "network-only" },
      company && {
        query: GET_COMPANY,
        variables: { id: Number(company.id) },
        fetchPolicy: "network-only",
      },
    ],
  });

  const [name, setName] = React.useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [osp, setOsp] = React.useState([]);

  const [selectedActions, setSelectedActions] = React.useState([]);
  const [unselectedActions, setUnselectedActions] = React.useState([]);

  React.useEffect(() => {
    if (actions) {
      setSelectedActions([]);
      setUnselectedActions(actions.companyArms.map((carm) => carm.arm));
    }
  }, [actions]);

  const onSave = () => {
    if (osp.length === 0) {
      onErrorFunc(
        "Include at least one company, site or project for your template."
      );
    } else if (name === "") {
      onErrorFunc("Include a name for this template.");
    } else if (!startDate || !endDate) {
      onErrorFunc("Include start date and end date of template.");
    } else {
      createTemplate({
        variables: {
          name,
          startDate: moment(startDate).format("YYYY-MM-DD"),
          endDate: moment(endDate).format("YYYY-MM-DD"),
          associatedOSP: osp.map((osp) => Number(osp.id)),
          unassociatedActions: [],
          associatedActions: selectedActions.map((a) => Number(a.id)),
        },
      });
    }
  };

  const onCancel = () => {
    onClear();
    handleClose();
  };

  const onClear = () => {
    setName("");
    setStartDate(null);
    setEndDate(null);
    setOsp([]);
    setSelectedActions([]);
    if (actions) {
      setUnselectedActions(actions.companyArms.map((carm) => carm.arm));
    }
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        CREATE TEMPLATE.
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomTextField
              label="Template Name:"
              variant="standard"
              value={name}
              onChange={(event) => setName(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MobileDatePicker
              label="Start Date:"
              inputFormat="MM/DD/YYYY"
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MobileDatePicker
              label="End Date:"
              inputFormat="MM/DD/YYYY"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => (
                <CustomTextField {...params} fullWidth variant="standard" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectOwnerSiteProject open={open} osp={osp} setOsp={setOsp} />
          </Grid>

          <Grid item xs={12}>
            <SelectedUnselected
              selected={selectedActions}
              unselected={unselectedActions}
              setSelected={setSelectedActions}
              setUnselected={setUnselectedActions}
              getName={(item) => item.a.name}
              label="Actions"
              selectedLabel
              showSearch
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onSave}
          loading={loading}
        >
          CREATE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
