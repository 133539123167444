import React from "react";

import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Autocomplete,
  TextField,
  IconButton,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

import PropTypes from "prop-types";

export default function TableFilterDialog({
  open,
  setOpen,
  filters,
  setFilters,
  handleFilter,
  clearFilters,
}) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              id="filter-status"
              options={["Active", "Inactive"]}
              value={filters.isActive ? "Active" : "Inactive"}
              onChange={(event, newValue) =>
                setFilters((prev) => ({
                  ...prev,
                  isActive: newValue === "Active",
                }))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Filter by Active"
                />
              )}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DesktopDatePicker
              label="Filter After This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.afterDate || null}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, afterDate: date || null }))
              }
              renderInput={(params) => (
                <TextField
                  fullWidth
                  variant="standard"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {filters.afterDate && (
                          <IconButton
                            onClick={() =>
                              setFilters((prev) => ({
                                ...prev,
                                afterDate: null,
                              }))
                            }
                          >
                            <Close />
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                />
              )}
              disableMaskedInput
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <DesktopDatePicker
              label="Filter Before This Date"
              inputFormat="MM/DD/yyyy"
              value={filters.beforeDate || null}
              onChange={(date) =>
                setFilters((prev) => ({ ...prev, beforeDate: date || null }))
              }
              renderInput={(params) => (
                <TextField
                  fullWidth
                  variant="standard"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {params.InputProps.endAdornment}
                        {filters.beforeDate && (
                          <IconButton
                            onClick={() =>
                              setFilters((prev) => ({
                                ...prev,
                                beforeDate: null,
                              }))
                            }
                          >
                            <Close />
                          </IconButton>
                        )}
                      </>
                    ),
                  }}
                />
              )}
              disableMaskedInput
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleFilter();
            setOpen(false);
          }}
          variant="contained"
          color="secondary"
        >
          FILTER
        </Button>
        <Button onClick={clearFilters} variant="contained" color="primary">
          CLEAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

TableFilterDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
};
