import React from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

import { useMutation, useLazyQuery } from "@apollo/client";

import { CREATE_EDIT_PROJECT } from "../../../../graphql/mutations/admin/planning-project";
import {
  GET_PROJECTS,
  GET_SITES,
} from "../../../../graphql/queries/admin/planning-project";
import { GET_SEARCH_TEMPLATES } from "../../../../graphql/queries/ha";
import sortByKeys from "../../../../utils/sortByKeys";
import { states } from "../../../../utils/states";
import {
  onCompletedFunc,
  onErrorFunc,
} from "../../../CustomComponents/OnErrorFunction";
import { CustomTextField } from "../../../CustomStyles/LightTextField";
import { CustomDialog } from "../../../CustomStyles/dialog";
import { pageTitleStyles } from "../../../CustomStyles/pageTitle";
import { COMPANY_ADMIN_OSP } from "../../Company/companyAdminQueries";
import SelectedUnselected from "../Template/SelectedUnselected";
import PreviewImages from "./PreviewImages";
import UploadDialog from "./Upload";

export default function CreateProject({ open, handleClose, company }) {
  const [getSites, { data: sites, loading: loadingSites }] =
    useLazyQuery(GET_SITES);
  const [getTemplates, { data: templates }] =
    useLazyQuery(GET_SEARCH_TEMPLATES);

  React.useEffect(() => {
    if (open) {
      getSites({
        fetchPolicy: "network-only",
      });
      getTemplates({
        fetchPolicy: "network-only",
      });
    }
  }, [open]);

  const [createProject, { loading: loadingCreate }] = useMutation(
    CREATE_EDIT_PROJECT,
    {
      onCompleted() {
        handleClose();
        onClear();
        onCompletedFunc("Project has been created");
      },
      onError(error) {
        onErrorFunc(error);
      },
      refetchQueries: [
        { query: GET_PROJECTS },
        company && {
          query: COMPANY_ADMIN_OSP,
          variables: { id: Number(company.id) },
        },
      ],
    }
  );

  const initialState = {
    locations: [],
    name: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    dollar: 0.0,
  };
  const [projectDetails, setProjectDetails] = React.useState(initialState);
  const [photos, setPhotos] = React.useState([]);

  const [selectedTemplates, setSelectedTemplates] = React.useState([]);
  const [unselectedTemplates, setUnselectedTemplates] = React.useState([]);

  React.useEffect(() => {
    if (templates) {
      setUnselectedTemplates(templates.templates);
    }
  }, [templates]);

  const onCreate = () => {
    if (projectDetails.name === "") {
      onErrorFunc("Include a name for this project.");
    } else if (projectDetails.state === "") {
      onErrorFunc("Include the state for this project's location.");
    } else if (projectDetails.locations.length === 0) {
      onErrorFunc("Include at least one site associated to this project.");
    } else {
      createProject({
        variables: {
          addrCity: projectDetails.city,
          addrLine1: projectDetails.addr1,
          addrLine2: projectDetails.addr2,
          addrState: projectDetails.state,
          addrZip: projectDetails.zip,
          dollarAmount: Number(projectDetails.dollar),
          media: photos,
          projectName: projectDetails.name,
          changeStatus: false,
          OSPinfo: projectDetails.locations.map((l) => {
            return {
              company: Number(l.owner.id),
              site: Number(l.site.id),
              associatedTemplate: selectedTemplates.map((t) => Number(t.id)),
            };
          }),
        },
      });
    }
  };

  const onCancel = () => {
    handleClose();
    onClear();
  };

  const onClear = () => {
    setProjectDetails(initialState);
    setPhotos([]);
    setSelectedTemplates([]);
    setUnselectedTemplates(templates?.templates);
  };

  const handleProjectDetailsChange = (event) => {
    const { name, value } = event.target;
    setProjectDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <CustomDialog open={open} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle
        style={{
          ...pageTitleStyles,
        }}
      >
        CREATE PROJECT.
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ marginBottom: "20px" }}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-site-owner"
              limitTags={3}
              options={
                sites
                  ? sortByKeys(
                      sites.ownerSiteProjects.filter((osp) =>
                        company
                          ? osp.owner && osp.owner.id === company.id && osp.site
                          : osp.owner && osp.site
                      ),
                      "site.name"
                    )
                  : []
              }
              value={projectDetails.locations}
              onChange={(event, value) =>
                setProjectDetails((prev) => ({
                  ...prev,
                  locations: value,
                }))
              }
              getOptionLabel={(option) =>
                `${option.site?.name} [${option.owner?.name}]`
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
              multiple
              loading={loadingSites}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {`${option.site.name} [${option.owner.name}]`}
                </li>
              )}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"Location:"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Project Name:"
              variant="standard"
              style={{ width: "100%" }}
              value={projectDetails.name}
              onChange={(event) => handleProjectDetailsChange(event)}
              name="name"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 1:"
              variant="standard"
              style={{ width: "100%" }}
              value={projectDetails.addr1}
              onChange={(event) => handleProjectDetailsChange(event)}
              name="addr1"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Address Line 2:"
              variant="standard"
              style={{ width: "100%" }}
              value={projectDetails.addr2}
              onChange={(event) => handleProjectDetailsChange(event)}
              name="addr2"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="City:"
              variant="standard"
              style={{ width: "100%" }}
              value={projectDetails.city}
              onChange={(event) => handleProjectDetailsChange(event)}
              name="city"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="select-site-state"
              options={states}
              value={projectDetails.state}
              onChange={(event, value) =>
                setProjectDetails((prev) => ({
                  ...prev,
                  state: value,
                }))
              }
              disableClearable
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  style={{ color: "#fff" }}
                  variant="standard"
                  label={"State:"}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Zipcode:"
              variant="standard"
              style={{ width: "100%" }}
              value={projectDetails.zip}
              onChange={(event) => handleProjectDetailsChange(event)}
              name="zip"
              type="number"
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomTextField
              label="Dollar Amount:"
              variant="standard"
              style={{ width: "100%" }}
              value={projectDetails.dollar}
              onChange={(event) => handleProjectDetailsChange(event)}
              name="dollar"
              type="number"
            />
          </Grid>

          <Grid item xs={12}>
            <SelectedUnselected
              selected={selectedTemplates}
              unselected={unselectedTemplates}
              setSelected={setSelectedTemplates}
              setUnselected={setUnselectedTemplates}
              getName={(item) => item.name}
              label="Templates"
              selectedLabel
              showSearch
            />
          </Grid>
        </Grid>

        <UploadDialog
          files={photos}
          handleFilesChange={(files) => setPhotos(files)}
        />
        <PreviewImages
          fileObjects={photos}
          handleFilesChange={(files) => setPhotos(files)}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          color="yellow0"
          sx={{ mr: 1 }}
          onClick={onCreate}
          loading={loadingCreate}
        >
          CREATE
        </LoadingButton>
        <Button variant="contained" onClick={onCancel}>
          CANCEL
        </Button>
      </DialogActions>
    </CustomDialog>
  );
}
